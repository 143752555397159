import { ImageLoaderProps } from 'next/image';

const urlMappings: { [key: string]: string } = {
    'images.beta.cademy.co.uk': 'https://cademy-images-beta.b-cdn.net',
    'images.cademy.co.uk': 'https://cademy-images.b-cdn.net',
    'assets.cademy.co.uk': 'https://cademy-assets.b-cdn.net',
};

export default function bunnyNetLoader({ src, width, quality }: ImageLoaderProps) {
    try {
        const url = new URL(src);
        const host = url.hostname;

        const mapping = urlMappings[host];
        if (mapping) {
            const qualityParam = quality ? `&quality=${quality}` : '';
            return `${mapping}${url.pathname}?width=${width}${qualityParam}`;
        }
    } catch (e) {
        console.error(e);
    }

    return src;
}
